html {
  background-color: #071b2e;
}
a {
  color: #9cebff;
}
a:visited {
  color: #ebbaff;
}

.App {
  background-color: #071b2e;
  min-height: 100vh;
  color: white;
  padding: 20px;
}
.spoiler {
  display: inline;
}
